import React  from "react"
import "../scss/main.scss"

const Layout = ({ children,white }) => {

  return (
    <div className={("color " + (white ? 'white' : ''))}>
      {children}
    </div>
  )
}
export default Layout
